<script lang="ts" setup>
import type { HomepageBrandCardMediaLogoProps } from './HomepageBrandCardMediaLogo.props'

const props = defineProps<HomepageBrandCardMediaLogoProps>()
const logo = computed(() =>
  props.mediaLogoType === 'extended' ? logoMap : menuLogoMap
)
</script>

<template>
  <component
    :is="logo[brand]"
    aria-hidden="true"
    class="homepage-logo-image-height text-primitives-white"
    :class="[
      blendMode ? 'mix-blend-exclusion' : 'opacity-70',
      mediaLogoType === 'monogram'
        ? 'homepage-card-logo-squared'
        : 'homepage-card-logo-rectangular',
    ]"
  />
</template>
