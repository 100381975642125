<script lang="ts" setup>
// Import the custom logger function

import type { HomepageCard } from './HomepageCard.props'
import snakeCase from 'lodash/snakeCase'

const props = defineProps<HomepageCard>()
const dataTrackingAttribute = snakeCase(props.link.target)

const { isOpen: isConfirmCountryOpen } = useConfirmCountry()
const videoRef = ref<HTMLVideoElement>()

enum VideoControl {
  Play = 'play',
  Stop = 'stop',
}

const videoElement = computed(() => videoRef.value?.querySelector('video'))

// Simplified video control function
const controlVideo = (action: VideoControl): void => {
  if (videoElement.value) {
    action === VideoControl.Play
      ? videoElement.value.play()
      : videoElement.value.pause()
  }
}

watch(
  () => props.isPlayable,
  shouldPlay => {
    if (!props.isPortraitCard || !videoElement.value) return

    controlVideo(shouldPlay ? VideoControl.Play : VideoControl.Stop)
  },
  { immediate: true }
)

onMounted(() => {
  props.isPlayable && controlVideo(VideoControl.Play)
})
</script>

<template>
  <div
    class="homepage-card-height group relative flex w-full flex-col items-center justify-start overflow-hidden"
    :class="{ 'page-has-confirm-country': isConfirmCountryOpen }"
    :data-testid="`brand-${link.target}`"
    @mouseenter="!isPortraitCard && controlVideo(VideoControl.Play)"
    @mouseleave="!isPortraitCard && controlVideo(VideoControl.Stop)"
  >
    <UILink
      v-bind="link"
      :data-tracking="`BA_${dataTrackingAttribute}`"
      pos-tracking="hero"
    >
      <div class="brand-media-logo-wrapper relative flex flex-col items-center">
        <!-- MEDIA -->
        <div class="homepage-image-height relative">
          <div
            ref="videoRef"
            class="hover-card-media absolute inset-0"
            :class="{ 'brightness-[0.3]': videoDarkOverlay }"
          >
            <UIMedia
              v-bind="{
                media: {
                  ...hoverMedia,
                  ...(hoverMedia.type === 'video' && {
                    autoplay: false,
                    preload: 'metadata',
                    muted: true,
                    playsinline: true,
                  }),
                },
              }"
              aspect-ratio="4/5"
              :fetch-priority-image="fetchPriorityImage"
              :skip-tab-navigation="true"
            />
          </div>
          <div class="absolute inset-0">
            <!-- MEDIA LOGO -->
            <slot name="media-logo" />
          </div>

          <div
            class="overlay-card-media transition-opacity duration-300 group-hover:opacity-0 portrait:hidden"
          >
            <UIMedia
              v-bind="{
                media: {
                  ...media,
                  ...(media.type === 'video' && {
                    autoplay: isPlayable,
                  }),
                },
              }"
              aspect-ratio="4/5"
              :fetch-priority-image="fetchPriorityImage"
            />
          </div>
        </div>
      </div>
    </UILink>
    <div
      class="homepage-logo-banner bg-neutral-white relative flex w-full flex-col items-center justify-center gap-2"
    >
      <!-- BANNER (LOGO) -->
      <div
        class="banner-logo-wrapper bg-neutral-white absolute left-0 right-0 top-1/2 -translate-y-1/2 transition-opacity duration-300 landscape:group-hover:hidden"
      >
        <slot name="banner-logo" />
      </div>
      <div
        class="banner-text-wrapper pointer-events-auto -z-[1] items-center justify-center group-focus-within:z-[0] group-hover:z-[0]"
      >
        <!-- BANNER (LABEL) -->
        <slot name="banner-text" />
      </div>
    </div>
  </div>
</template>
